import React, { useMemo } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useClient } from "hooks";
import { useFormik, Field, FormikProvider, Form, useField } from "formik";
import {
  Page,
  FormGroup as BaseFormGroup,
  FileField,
  FormCheck,
  SubmitButton,
  Loading,
  BooleanIcon,
} from "components/common";
import BaseDatePicker from "react-datepicker";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { useQuery } from "react-query";
import { Table, Button } from "reactstrap";
import { formatDateTime } from "services/formatters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Select from "../components/common/Select";

const FormGroup = (props) => (
  <BaseFormGroup {...props} translateLabel={false} />
);

const DateTimePicker = ({ name, validate, ...props }) => {
  const [{ value, onBlur }, , { setValue }] = useField({
    name,
    validate,
  });

  const selected = useMemo(() => {
    if (!value) return null;
    return moment(value).toDate();
  }, [value]);

  return (
    <BaseDatePicker
      dateFormat="dd/MM/yyyy hh:mm"
      showTime
      name={name}
      selected={selected}
      onChange={(v) => {
        setValue(v ? moment(v).format() : "");
      }}
      onBlur={onBlur}
      autoComplete="off"
      showTimeSelect
      timeIntervals={10}
      {...props}
    />
  );
};

export const DatabaseImport = () => {
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      file: null,
      description: "",
      start_time: moment().format(),
      force_insert: false,
      encoding: "windows-1252",
      error: null,
    },
    onSubmit,
  });
  const { resetForm } = formik;
  const client = useClient();
  const items = useQuery(
    "database_imports",
    async () => {
      return client.get("database_imports").get("data");
    },
    {
      refetchInterval: 60 * 1000,
    }
  );

  const { data: encodingChoices } = useQuery(
    `database_imports/encoding_choices`,
    ({ queryKey }) => client.get(...queryKey).get("data"),
    {
      placeholderData: [],
    }
  );

  const { data, refetch, isFetching } = items;

  async function onSubmit(x) {
    await client.post("database_imports", x).get("data");
    NotificationManager.success(t("Database import successfully submitted"));
    resetForm();
    refetch();
  }

  return (
    <Page title={t("Database import")} fluid>
      <FormikProvider value={formik}>
        <Form className="mb-4">
          <FormGroup label={t("Description")} name="description">
            <Field name="description" className="form-control" />
          </FormGroup>
          <FormGroup label={t("Database file")} name="file" showError>
            <FileField
              name="file"
              validate={(v) => {
                if (!v) return true;
                if (!/\.zip$/i.test(v.name)) return "File must be a *.zip file";
                return null;
              }}
            />
          </FormGroup>
          <FormGroup label={t("Start time")} name="start_time">
            <DateTimePicker
              name="start_time"
              className="form-control"
              validate={(v) => !v}
            />
          </FormGroup>
          <FormGroup label={t("Encoding")} name="encoding">
            <Select
              simpleValue
              validate={(v) => !v}
              options={encodingChoices}
              name="encoding"
            />
          </FormGroup>
          <FormCheck
            label={t("This import only contains new data")}
            name="force_insert"
            input={
              <Field
                name="force_insert"
                type="checkbox"
                id="start_time"
                className="form-check-input"
              />
            }
          />
          <div className="flex justify-end">
            <SubmitButton>{t("Submit")}</SubmitButton>
          </div>
        </Form>
      </FormikProvider>
      <Table size="sm" striped className="text-sm">
        <thead>
          <tr>
            <th />
            <th>
              <Trans>Description</Trans>
            </th>
            <th className="text-right">
              <Trans>Created</Trans>
            </th>
            <th>
              <Trans>Filename</Trans>
            </th>
            <th className="text-right">
              <Trans>Start time</Trans>
            </th>
            <th className="text-right">
              <Trans>Started</Trans>
            </th>
            <th className="text-right">
              <Trans>Finished</Trans>
            </th>
            <th className="text-center">
              <Trans>Success</Trans>
            </th>
            <th>
              <Trans>Error</Trans>
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {data?.map((i) => (
            <tr key={i.id}>
              <td>
                {i.started && !i.finished ? (
                  <Loading className="px-1 text-gray-700" text="" />
                ) : null}
              </td>
              <td className="text-sm">{i.description}</td>
              <td className="text-right">{formatDateTime(i.created)}</td>
              <td>{i.file__name}</td>
              <td className="text-right">{formatDateTime(i.start_time)}</td>
              <td className="text-right">
                {i.started ? formatDateTime(i.started) : null}
              </td>
              <td className="text-right">
                {i.finished ? formatDateTime(i.finished) : null}
              </td>
              <td className="text-center">
                {i.success !== null ? <BooleanIcon test={i.success} /> : null}
              </td>
              <td className="whitespace-pre-line font-mono">{i.error}</td>
              <td>
                <Button
                  color="link"
                  className="text-red-500"
                  size="sm"
                  onClick={async () => {
                    await client.delete(`database_imports/${i.id}`);
                    NotificationManager.success(t("Successfully deleted"));
                    refetch();
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
        {isFetching ? (
          <tfoot>
            <tr className="text-center">
              <td colSpan={999}>
                <Loading text={t("Loading...")} />
              </td>
            </tr>
          </tfoot>
        ) : null}
      </Table>
    </Page>
  );
};
