import React, { useState, useEffect, useMemo } from "react";
import {
  useAsyncEffect,
  useRouteModal,
  useModal,
  useParticipantClient,
  useUserContext,
} from "hooks";
import { ModalContextProvider } from "contexts/ModalContext";
import {
  Button,
  SubmitButton as BaseSubmitButton,
  FormGroup,
  FormCheck,
  YesNoField,
} from "components/common";
import {
  chain,
  keys,
  isEmpty,
  includes,
  map,
  reduce,
  indexOf,
  maxBy,
} from "lodash";
import { Trans, useTranslation } from "react-i18next";
import { useFormik, FormikProvider, Field } from "formik";
import {
  Card,
  Row,
  Col,
  CardBody,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import {Link, useNavigate} from "react-router-dom";
import {
  faSearch,
  faPlusCircle,
  faEdit,
  faClipboard,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchModal from "./SearchModal";
import { DiaryContextProvider } from "./DiaryContext";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import numeral from "numeral";
import { useParticipantContext } from "../../contexts/useParticipantContext";
import { formatDate } from "services/formatters";
import { Title } from "./components/Title";
import { SubTitle } from "./components/SubTitle";
import { CardHeader } from "./components/CardHeader";
import { ModalHeader } from "./components/ModalHeader";
import { CopyToClipboard } from "react-copy-to-clipboard";

const SUBMIT = "SUBMIT";
const ADDITIONAL_QUESTIONS = "ADDITIONAL_QUESTIONS";

const SubmitButton = ({ questions, className, color = "secondary", style }) => {
  const { isOpen, setIsOpen, toggle, Modal, ModalBody } = useModal();
  const [view, setView] = useState(SUBMIT);
  const client = useParticipantClient();
  const { participant, project, date } = useParticipantContext();
  const { unique_identifier } = participant;
  const { show_participant_unique_identifier } = project;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const formik = useFormik({
    onSubmit,
    initialValues: {},
  });
  const { resetForm, values } = formik;
  const { question_answers: _questionAnswers } = participant;

  const questionAnswers = useMemo(() => {
    if (!_questionAnswers) return {};
    return _questionAnswers.reduce((acc, i) => {
      if (!date || date === i.date) {
        acc[i.question] = i.answer;
      }
      return acc;
    }, {});
  }, [_questionAnswers, date]);

  useEffect(() => {
    resetForm({
      values:
        view === ADDITIONAL_QUESTIONS
          ? {
              answers: questions.map((i) => ({
                question: i.id,
                question__question_text: i.question_text,
                question__type: i.type,
                question__attributes: i.attributes,
                answer:
                  questionAnswers[i.id] ??
                  (i.type === "multiple"
                    ? {
                        answer: reduce(
                          i.attributes.inputs,
                          (acc, v, k) => {
                            acc[k] = "";
                            return acc;
                          },
                          {}
                        ),
                      }
                    : {
                        answer: "",
                      }),
              })),
            }
          : {
              answers: [],
            },
    });
  }, [view, resetForm, questions, questionAnswers]);

  async function onSubmit({ answers }) {
    const { redirect_to } = await client
      .post("diary/submit", { answers, date })
      .get("data");

    if (redirect_to) {
      window.location.href = redirect_to;
    } else {
      setIsOpen(false);
      navigate("submit-success");
    }
  }

  const returnToDiary = (
    <Button className="mr-4" color="link" onClick={() => setIsOpen(false)}>
      <Trans>Return to Diary</Trans>
    </Button>
  );

  return (
    <>
      <Button
        onClick={() => {
          setView(SUBMIT);
          setIsOpen(true);
        }}
        className={className}
        color={color}
        style={style}
      >
        <Trans>Submit</Trans>
      </Button>
      <FormikProvider value={formik}>
        {isOpen ? (
          <Modal size="lg">
            {view === SUBMIT ? (
              <>
                <ModalHeader toggle={toggle}>
                  <Trans>Is there anything you've forgotten?</Trans>
                </ModalHeader>
                <ModalBody>
                  <div className="prose">
                    <div className="text-center font-medium">
                      <p>
                        <Trans>
                          Please review your meal record carefully to check you
                          have entered everything.
                        </Trans>
                        <br />
                        <Trans>
                          If you are happy that you have entered everything then
                          you are ready to confirm and continue
                        </Trans>
                      </p>
                    </div>
                    <Card body className="border-brand-light">
                      <div className="prose-sm">
                        <p className="mb-0">
                          <Trans>
                            The following is a list of commonly forgotten foods,
                            if you have missed anything please return to your
                            diary and add it.
                          </Trans>
                        </p>
                        <ul className="mt-0">
                          <li>
                            <Trans>
                              Coffee, tea, soft drinks, fizzy drinks, milk,
                              juice or water
                            </Trans>
                          </li>
                          <li>
                            <Trans>Beer, wine, cocktails or other drinks</Trans>
                          </li>
                          <li>
                            <Trans>
                              Biscuits, chocolates, ice cream or other sweets
                            </Trans>
                          </li>
                          <li>
                            <Trans>
                              Crisps, crackers, nuts or other snack foods
                            </Trans>
                          </li>
                          <li>
                            <Trans>Butter, margarine or other spread</Trans>
                          </li>
                          <li>
                            <Trans>Fruits, vegetables or cheese</Trans>
                          </li>
                          <li>
                            <Trans>Bread, rolls, chapatti or other bread</Trans>
                          </li>
                        </ul>
                      </div>
                    </Card>
                  </div>
                  <div className="flex justify-end mt-4">
                    {returnToDiary}
                    {questions.length || show_participant_unique_identifier ? (
                      <Button
                        color="primary"
                        onClick={() => setView(ADDITIONAL_QUESTIONS)}
                      >
                        <Trans>Confirm & Continue</Trans>
                      </Button>
                    ) : (
                      <BaseSubmitButton color="primary">
                        <Trans>Confirm & Continue</Trans>
                      </BaseSubmitButton>
                    )}
                  </div>
                </ModalBody>
              </>
            ) : null}
            {view === ADDITIONAL_QUESTIONS && (
              <>
                <ModalHeader toggle={toggle}>
                  <Trans>Additional Questions</Trans>
                </ModalHeader>
                <ModalBody>
                  <div className="prose text-center font-medium">
                    <p>
                      <Trans>
                        Please could you take a few moments to complete the
                        following questions before you submit your diary...
                      </Trans>
                    </p>
                  </div>
                  <hr />

                  {!isEmpty(values) ? (
                    <>
                      {values.answers.map((i, idx) => (
                        <FormGroup
                          translateLabel={false}
                          label={t(i.question__question_text)}
                          name={`answers.${idx}.answer.answer`}
                        >
                          {i.question__type === "textarea" ? (
                            <Field
                              name={`answers.${idx}.answer.answer`}
                              as="textarea"
                              rows={3}
                              className="form-control"
                            />
                          ) : null}
                          {i.question__type === "radio" ? (
                            <>
                              {map(
                                i.question__attributes?.choices ?? {},
                                (v, k) => (
                                  <FormCheck
                                    key={k}
                                    label={t(v)}
                                    input={
                                      <Field
                                        name={`answers.${idx}.answer.answer`}
                                        type="radio"
                                        className="form-check-input"
                                        value={k}
                                      />
                                    }
                                  />
                                )
                              )}
                            </>
                          ) : null}
                          {i.question__type === "multiple" ? (
                            <>
                              {map(i.question__attributes.inputs, (v, k) => {
                                const { type, label } = v;
                                if (type !== "text" || !label) return null;

                                return (
                                  <Field
                                    name={`answers.${idx}.answer.answer.${k}`}
                                    className="form-control mb-1"
                                    placeholder={t(label)}
                                  />
                                );
                              })}
                            </>
                          ) : null}
                        </FormGroup>
                      ))}
                    </>
                  ) : null}
                  {unique_identifier ? (
                    <>
                      {questions.length ? <hr /> : null}
                      <FormGroup label="Please record this unique identifier as you will be redirected to another page">
                        <InputGroup>
                          <input
                            type="text"
                            readOnly
                            className="form-control"
                            key={unique_identifier}
                            defaultValue={unique_identifier}
                          />
                          <InputGroupAddon addonType="append">
                            <CopyToClipboard
                              text={unique_identifier}
                              onCopy={() => {
                                NotificationManager.success(
                                  t("Copied to clipboard")
                                );
                              }}
                            >
                              <Button color="primary" outline>
                                <FontAwesomeIcon icon={faClipboard} />
                                <span className="mx-1">
                                  <Trans>copy to clipboard</Trans>
                                </span>
                              </Button>
                            </CopyToClipboard>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </>
                  ) : null}
                  <div className="flex justify-end">
                    {returnToDiary}
                    <BaseSubmitButton color="primary">
                      <Trans>Submit</Trans>
                    </BaseSubmitButton>
                  </div>
                </ModalBody>
              </>
            )}
          </Modal>
        ) : null}
      </FormikProvider>
    </>
  );
};

const ClearDiaryButton = ({ onConfirm: onConfirmProp }) => {
  const { isOpen, setIsOpen, toggle, Modal, ModalBody } = useModal();
  const [pending, setPending] = useState(false);

  async function onConfirm() {
    setPending(true);

    try {
      await onConfirmProp();
      setIsOpen(false);
    } finally {
      setPending(false);
    }
  }

  return (
    <>
      <Button color="link" className="mr-2" onClick={() => setIsOpen(true)}>
        <Trans>Clear Diary</Trans>
      </Button>
      {isOpen ? (
        <Modal>
          <ModalHeader toggle={toggle}>
            <Trans>Clear Diary Entries</Trans>
          </ModalHeader>
          <ModalBody>
            <p className="text-center">
              <Trans>
                Are you sure you wish to clear all of your diary entries? This
                operation is not reversible.
              </Trans>
            </p>

            <div className="flex justify-end mt-4">
              <Button color="primary" onClick={onConfirm} pending={pending}>
                <Trans>Clear Diary</Trans>
              </Button>
            </div>
          </ModalBody>
        </Modal>
      ) : null}
    </>
  );
};

const DeleteButton = ({ onConfirm: onConfirmProp }) => {
  const { isOpen, setIsOpen, Modal, ModalBody } = useModal();
  const [pending, setPending] = useState(false);

  async function onConfirm() {
    setPending(true);

    try {
      await onConfirmProp();
      setIsOpen(false);
    } finally {
      setPending(false);
    }
  }

  return (
    <>
      <Button
        color="link"
        className="text-red-500"
        onClick={() => setIsOpen(true)}
      >
        <FontAwesomeIcon icon={faTimes} />
      </Button>
      {isOpen ? (
        <Modal>
          <ModalHeader>
            <Trans>Delete Diary Entry</Trans>
          </ModalHeader>
          <ModalBody>
            <p className="text-center">
              <Trans>
                Are you sure you wish to delete this diary entry? This operation
                is not reversible.
              </Trans>
            </p>

            <div className="flex justify-end mt-4">
              <Button color="primary" onClick={onConfirm} pending={pending}>
                <Trans>Delete Diary Entry</Trans>
              </Button>
            </div>
          </ModalBody>
        </Modal>
      ) : null}
    </>
  );
};

const mealOrdering = ["breakfast", "lunch", "evening", "snack", "drink"];

const getHourMinute = (time) => {
  const t = moment(time, "HH:mm:ss");
  let formattedTime,
    hour,
    minute = null;

  if (t.isValid()) {
    formattedTime = t.format("h:mma");
    hour = t.format("HH");
    minute = t.format("mm");
  }

  return {
    formattedTime,
    hour,
    minute,
  };
};

const EligibilityAndConsent = () => {
  const { Modal, setIsOpen, isOpen } = useModal();
  const { logout } = useUserContext();
  const { participant, project } = useParticipantContext();
  const { consented } = participant;
  const { t } = useTranslation();
  const {
    show_eligibility_questions: _show_eligibility_questions,
    eligibility_questions,
    show_consent_question,
    consent_text,
  } = project;
  const formik = useFormik({
    initialValues: {
      eligibility_questions: [],
      consent_question: null,
    },
    onSubmit,
  });
  const show_eligibility_questions = useMemo(
    () => _show_eligibility_questions && eligibility_questions.length,
    [_show_eligibility_questions, eligibility_questions]
  );

  const { submitCount, errors } = formik;
  const client = useParticipantClient();

  async function onSubmit() {
    await client.post("consent");
    setIsOpen(false);
  }

  const consentError = errors.consent_question;
  const eligibilityQuestionsError = !isEmpty(errors.eligibility_questions);

  const error = useMemo(() => {
    if (consentError && !eligibilityQuestionsError) {
      return t('Thank you for considering joining our study, we are sorry you do not consent to take part.')
    } else if (!consentError && eligibilityQuestionsError) {
      return t('Thank you for considering joining our study, we are sorry you are not eligible to take part.')
    } else if (consentError && eligibilityQuestionsError) {
      return t('Thank you for considering joining our study, we are sorry you are not able to take part.')
    } else {
      return ''
    }
  }, [consentError, eligibilityQuestionsError, t]);

  const titles = {
    0: t("Participant Consent"),
    1: t("Participant Eligibility & Consent"),
    2: t("Participant Eligibility"),
  };

  let title = "";

  if (show_eligibility_questions && show_consent_question) {
    title = titles[1];
  } else if (show_eligibility_questions) {
    title = titles[2];
  } else if (show_consent_question) {
    title = titles[0];
  }

  useEffect(() => {
    if ((show_eligibility_questions || show_consent_question) && !consented) {
      setIsOpen(true);
    }
  }, [show_eligibility_questions, show_consent_question, consented, setIsOpen]);

  return (
    <Modal toggle={false} backdrop size="lg">
      <ModalHeader>{title}</ModalHeader>
      {isOpen ? (
        <FormikProvider value={formik}>
          <ModalBody>
            {show_eligibility_questions
              ? eligibility_questions.map((i, idx) => (
                  <FormGroup
                    translateLabel={false}
                    label={i.question}
                  >
                    <YesNoField
                      validate={(v) => v !== i.required_answer}
                      name={`eligibility_questions.${idx}.answer`}
                    />
                  </FormGroup>
                ))
              : null}
            {show_consent_question && show_eligibility_questions ? (
              <hr/>
            ) : null}
            {show_consent_question ? (
              <>
                <FormGroup
                  translateLabel={false}
                  label={(
                    <>
                      {consent_text ? (
                        <p className="mb-2">
                          {consent_text}
                        </p>
                      ) : null}
                      {t("Do you consent to participate in this study?")}
                    </>
                  )}
                >
                  <YesNoField validate={(v) => v !== 1} name="consent_question" />
                  {project.consent_document__name ? (
                    <p className="text-grey-500 text-sm mt-1">
                      <Trans>
                        View more information{" "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`/api/projects/${project.id}/consent_document/${project.consent_document__name}`}
                        >
                          here
                        </a>
                      </Trans>
                    </p>
                  ) : null}
                </FormGroup>
              </>
            ) : null}
            {submitCount && error ? (
              <>
                <hr />
                <div className="text-center">
                  <p>
                    {error}
                  </p>
                  <p>
                    <Trans>You can now close this window or <Link to="logout" onClick={async () => {
                      await logout();
                    }}>logout</Link>.</Trans>
                  </p>
                </div>
              </>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <BaseSubmitButton>
              <Trans>Continue</Trans>
            </BaseSubmitButton>
          </ModalFooter>
        </FormikProvider>
      ) : null}
    </Modal>
  );
};

export const Diary = () => {
  const client = useParticipantClient();
  const [data, setData] = useState();
  const [selectedMeal, setSelectedMeal] = useState();
  const [lastTime, setLastTime] = useState();
  const [questions, setQuestions] = useState();
  const modal = useRouteModal("m");
  const { Modal, isOpen } = modal;
  const navigate = useNavigate();
  const { participant, project, date } = useParticipantContext();
  const { user } = useUserContext();
  const { t } = useTranslation();
  const [, setSearch] = useState("");

  async function load() {
    const params = {
      date
    }
    const [meals, foodEntries, questions, recipeEntries] = await Promise.all([
      client.get("diary/meal_choices").get("data"),
      client.get("diary/food_entries", { params }).get("data"),
      client.get("diary/questions").get("data"),
      client.get("diary/recipe_entries", { params }).get("data"),
    ]);
    setQuestions(
      questions.filter(
        (i) => i.is_mandatory || includes(project.questions, i.id)
      )
    );
    setData(
      chain([...foodEntries, ...recipeEntries])
        .orderBy("time")
        .groupBy("meal")
        .thru((v) => {
          keys(meals).forEach((m) => {
            if (!v[m]) v[m] = [];
          });
          return v;
        })
        .map((v, meal) => ({
          lastEntry: maxBy(v, "created"),
          meal,
          mealT: meals[meal],
          times: chain(v)
            .groupBy("time")
            .map((entries, time) => {
              const { formattedTime, hour, minute } = getHourMinute(time);

              return {
                hour,
                minute,
                formattedTime,
                time,
                entries,
              };
            })
            .value(),
        }))
        .sortBy((i) => indexOf(mealOrdering, i.meal))
        .value()
    );
  }

  useEffect(() => {
    if (!isOpen) {
      setSelectedMeal(null);
      setLastTime(null);
      setSearch("");
    }
  }, [isOpen, setSearch]);

  useAsyncEffect(load, []);

  if (!data) return null;

  const participantDate = formatDate(date ?? participant.participant_date);

  return (
    <div>
      <EligibilityAndConsent />
      <div className="px-4 sm:px-0">
        <Title>
          <Trans>Welcome to myfood24</Trans>
          {user.is_unknown ? "" : `, ${user.full_name}`}
        </Title>
        <DiaryContextProvider
          reload={load}
          lastTime={lastTime}
          meal={selectedMeal}
        >
          {isOpen ? (
            <Modal size="lg">
              <ModalContextProvider value={modal}>
                <SearchModal />
              </ModalContextProvider>
            </Modal>
          ) : null}
        </DiaryContextProvider>
        <SubTitle>
          {!date && (!participant.is_student || participant.is_participant_date_today) ? (
            project.is_recall_today ? (
              <Trans>
                Please tell us everything you have to eat and drink{" "}
                <strong>today</strong>, from midnight last night to midnight
                tonight.
              </Trans>
            ) : (
              <Trans>
                Please tell us everything that you had to eat and drink{" "}
                <strong>yesterday</strong> from midnight to midnight.
              </Trans>
            )
          ) : (
            <Trans>
              Please tell us everything that you had to eat and drink on{" "}
              <strong>{{ participantDate }}</strong>, midnight to midnight.
            </Trans>
          )}{" "}
          <Trans>
            Items are saved as you go, please only submit once you've finished.
          </Trans>
        </SubTitle>
        <div className="flex justify-center">
          <Button
            outline
            size="lg"
            onClick={() => {
              setSelectedMeal(null);
              setLastTime(null);
              navigate("m/search/all");
            }}
          >
            <FontAwesomeIcon icon={faSearch} className="mr-2 opacity-50" />{" "}
            <Trans>Search</Trans>
          </Button>
        </div>
      </div>
      <div className="my-4">
        <Card className="p-0 my-4 border-brand-light">
          {data.map(({ meal, mealT, lastEntry, times }) => (
            <React.Fragment key={meal}>
              <CardHeader
                role="button"
                className="flex justify-between"
                selected={selectedMeal === meal}
                onClick={() => {
                  setSelectedMeal(meal);

                  if (meal !== "snack") {
                    const { hour, minute } = getHourMinute(lastEntry?.time);

                    setLastTime({
                      hour,
                      minute,
                    });
                  }

                  navigate("m/search/all");
                }}
              >
                {t(mealT)}
                {selectedMeal !== meal ? (
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    className="ml-auto text-brand-dark"
                  />
                ) : null}
              </CardHeader>
              <CardBody className="py-0">
                {times.map(({ time, formattedTime, entries }) => (
                  <div key={time}>
                    {formattedTime ? (
                      <div
                        className={"py-4 opacity-75 border-b border-gray-300"}
                      >
                        {formattedTime}
                      </div>
                    ) : null}
                    {entries.map((i, idx) => (
                      <div
                        key={i.id}
                        className={"border-b border-gray-300 py-4"}
                      >
                        <Row className="items-center">
                          {i.recipe ? (
                            <>
                              <Col className="font-semibold">
                                {i.recipe__name}
                              </Col>
                              <Col xs="auto" md>
                                {numeral(i.exact_size).format("0.[00]")} g
                              </Col>
                              <Col xs="auto">
                                <Button
                                  color="link"
                                  className="text-secondary mr-1"
                                  onClick={() => {
                                    navigate(`./m/recipe_entry/${i.id}`);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faEdit} />
                                </Button>
                                <DeleteButton
                                  onConfirm={async () => {
                                    await client.delete(
                                      `diary/recipe_entries/${i.id}`
                                    );
                                    await load();
                                    NotificationManager.success(
                                      t(
                                        "The diary entry has been successfully deleted!"
                                      )
                                    );
                                  }}
                                />
                              </Col>
                            </>
                          ) : (
                            <>
                              <Col className="font-semibold">
                                {i.food_item__description}
                              </Col>
                              <Col xs="auto" md>
                                {numeral(i.actual_size).format("0[.]00")}{" "}
                                <Trans
                                  i18nKey={
                                    i.actual_size_convertible_unit__unit || "g"
                                  }
                                />
                              </Col>
                              <Col xs="auto">
                                <Button
                                  color="link"
                                  className="text-secondary mr-1"
                                  onClick={() => {
                                    navigate(`./m/food_entry/${i.id}`);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faEdit} />
                                </Button>
                                <DeleteButton
                                  onConfirm={async () => {
                                    await client.delete(
                                      `diary/food_entries/${i.id}`
                                    );
                                    await load();
                                    NotificationManager.success(
                                      t(
                                        "The diary entry has been successfully deleted!"
                                      )
                                    );
                                  }}
                                />
                              </Col>
                            </>
                          )}
                        </Row>
                      </div>
                    ))}
                  </div>
                ))}
                {times.length === 0 ? (
                  <div className="py-4">
                    <Trans>No Entries</Trans>
                  </div>
                ) : null}
              </CardBody>
            </React.Fragment>
          ))}
        </Card>
        <div className="flex justify-end">
          <ClearDiaryButton
            onConfirm={async () => {
              await client.post("diary/clear", { date }).get("data");
              await load();
              NotificationManager.success(t("Diary successfully cleared"));
            }}
          />
          <SubmitButton
            questions={questions}
            color="primary"
            className="mr-2"
          />
        </div>
      </div>
    </div>
  );
};

export default Diary;
