import React, { createContext, useContext, useMemo, useState } from "react";
import { useEventCallback } from "hooks";

export const DiaryContext = createContext();

export const useDiaryContext = () => useContext(DiaryContext);

export const DiaryContextProvider = ({
  reload: reloadProp,
  meal,
  lastTime: lastTimeProp,
  children,
}) => {
  const reload = useEventCallback(reloadProp);
  const [currentRecipe, setCurrentRecipe] = useState();
  const [unsavedRecipe, setUnsavedRecipe] = useState();
  const [listSearch, setListSearch] = useState("");
  const [listItems, setListItems] = useState([]);
  const [listInPlay, setListInPlay] = useState(false);
  const [lastTime, setLastTime] = useState(lastTimeProp);
  const [search, setSearch] = useState();

  const popListItem = useEventCallback(() => {
    const items = [...listItems];
    const item = items.shift();
    setListItems(items);
    return item ?? null;
  });

  const value = useMemo(
    () => ({
      reload,
      currentRecipe,
      setCurrentRecipe,
      unsavedRecipe,
      setUnsavedRecipe,
      search,
      setSearch,
      listSearch,
      setListSearch,
      listItems,
      setListItems,
      listInPlay,
      setListInPlay,
      meal,
      popListItem,
      lastTime,
      setLastTime,
    }),
    [
      reload,
      unsavedRecipe,
      setUnsavedRecipe,
      currentRecipe,
      setCurrentRecipe,
      setSearch,
      search,
      listSearch,
      listItems,
      setListItems,
      listInPlay,
      setListInPlay,
      setListSearch,
      meal,
      lastTime,
      popListItem,
      setLastTime,
    ]
  );

  return (
    <DiaryContext.Provider value={value}>{children}</DiaryContext.Provider>
  );
};
