import YesNoField from "./YesNoField";
import { FormGroup } from "components/common";
import React from "react";

export const DiaryOptionsField = () => {
  return (
    <>
      <FormGroup label="On completion display calorie summary?" required>
        <YesNoField name="show_calorie_consumption" />
      </FormGroup>
      <FormGroup label="Reset Food Diary after 24 hours?" required>
        <YesNoField name="can_reset_recall_day" />
      </FormGroup>
      <FormGroup label="Log specific time of food entry?" required>
        <YesNoField name="log_food_time" />
      </FormGroup>
      <FormGroup label="Log consumption method?" required>
        <YesNoField name="log_method_of_consumption" />
      </FormGroup>
      <FormGroup label="Show the welcome screen?" required>
        <YesNoField name="show_welcome" />
      </FormGroup>
      <FormGroup label="Hide diary converter?" required>
        <YesNoField name="hide_diary_converter" />
      </FormGroup>
    </>
  );
};

export default DiaryOptionsField;
